import { UPDATE_CREDENTIAL, UPDATE_RECIPE_EDITABILITY } from "../actionTypes";

import produce from "immer";
// import RecipeModel from "../../easy-cooking-app/model/Recipe.model";

const initialState: {
  recipeEditable: boolean;
  credential: string;
} = {
  recipeEditable: false,
  credential: "",
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_RECIPE_EDITABILITY: {
      const newState = produce(state, (draftState) => {
        draftState.recipeEditable = action.recipeEditable;
      });
      return newState;
    }

    case UPDATE_CREDENTIAL: {
      const newState = produce(state, (draftState) => {
        draftState.credential = action.credential;
      });
      return newState;
    }

    default:
      return state;
  }
}

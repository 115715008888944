// import { Action, ActionCreator, Dispatch } from "redux";
// import { ThunkAction } from "redux-thunk";
import {
  ADD_RECIPE_TO_SELECTED_LIST,
  SELECT_RECIPE,
  REMOVE_RECIPE_FROM_SELECTED_LIST,
  UPDATE_RECIPE_LIST,
  UPDATE_RECIPE_QUANTITY,
  UPDATE_RECIPE_FIELD,
  UPDATE_RECIPE_EDITABILITY,
  REPLACE_RECIPE,
  UPDATE_CREDENTIAL,
} from "./actionTypes";
import axios from "axios";
import { batch } from "react-redux";
import { constants } from "../utils/Constants";
import RecipeModel from "../easy-cooking-app/model/Recipe.model";

// import { printAllRecipies } from "../utils/videoCalculations";

export const addRecipeToSelectedList = (recipeId: string) => {
  return {
    type: ADD_RECIPE_TO_SELECTED_LIST,
    payload: { recipeId },
  };
};

export const selectRecipe = (recipeId: string) => ({
  type: SELECT_RECIPE,
  payload: recipeId,
});

export const removeRecipeFromSelectedList = (recipeId: string) => ({
  type: REMOVE_RECIPE_FROM_SELECTED_LIST,
  payload: { recipeId },
});

export const saveRecipe = (recipe: RecipeModel, credential: string) => {
  return (dispatch: any) => {
    axios
      .post(constants.serviceUrl, { ...recipe, credential })
      .then((response) => {
        let recipe = response.data;
        batch(() => {
          dispatch(replaceRecipe(recipe));
          dispatch(updateRecipeEditability(false));
        });
      })
      .catch((error) => {
        //todo: write failure case here.
      });
  };
};

export const getRecipeFromServerAndReplaceLocal = (recipeId: string) => {
  return (dispatch: any) => {
    axios
      .get(constants.serviceUrl + "/" + recipeId)
      .then((response) => {
        let recipe = response.data;
        batch(() => {
          dispatch(replaceRecipe(recipe));
          dispatch(updateRecipeEditability(false));
        });
      })
      .catch((error) => {
        //todo: write failure case here.
      });
  };
};

const replaceRecipe = (recipe: RecipeModel) => ({
  type: REPLACE_RECIPE,
  payload: { recipe },
});

export const fetchRecipeList = () => {
  return (dispatch: any) => {
    axios
      .get(constants.serviceUrl)
      .then((response) => {
        let recipes = response.data;
        recipes = recipes.reverse();
        console.log("recipes fetched from server:", recipes);
        dispatch(updateRecipeList(recipes));
      })
      .catch((error) => {
        //todo: write failure case here.
      });
  };
};

export const updateRecipeList = (recipeList: RecipeModel[]) => ({
  type: UPDATE_RECIPE_LIST,
  payload: { recipeList },
});

export const updateRecipeQuantity = (
  recipeId: string,
  recipeQuantity: number
) => ({
  type: UPDATE_RECIPE_QUANTITY,
  payload: {
    recipeId,
    recipeQuantity,
  },
});

export const updateRecipe = (
  recipeId: string,
  fieldPath: string[],
  updatedValue: number | string
) => ({
  type: UPDATE_RECIPE_FIELD,
  payload: {
    recipeId,
    fieldPath,
    updatedValue,
  },
});

export const updateRecipeEditability = (recipeEditable: boolean) => ({
  type: UPDATE_RECIPE_EDITABILITY,
  recipeEditable,
});

export const updateCredential = (credential: string) => {
  return {
    type: UPDATE_CREDENTIAL,
    credential,
  };
};

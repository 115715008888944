export const ADD_RECIPE_TO_SELECTED_LIST = "ADD_RECIPE_TO_SELECTED_LIST";
export const SELECT_RECIPE = "SELECT_RECIPE";
export const REMOVE_RECIPE_FROM_SELECTED_LIST =
  "REMOVE_RECIPE_FROM_SELECTED_LIST";
export const UPDATE_RECIPE_LIST = "UPDATE_RECIPE_LIST";
export const UPDATE_RECIPE_QUANTITY = "UPDATE_RECIPE_QUANTITY";
export const UPDATE_RECIPE_FIELD = "UPDATE_RECIPE_FIELD";
export const REPLACE_RECIPE = "REPLACE_RECIPE"; //REPLACE FULL RECIPE
// OBJECT WITH ANOTHER
export const UPDATE_CREDENTIAL = "UPDATE_CREDENTIAL";
export const UPDATE_RECIPE_EDITABILITY = "UPDATE_RECIPE_EDITABILITY";
